// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/P8rPmEmnE";

const cycleOrder = ["pTtJnsyxM", "XtSgKKwNB"];

const serializationHash = "framer-CC3hP"

const variantClassNames = {pTtJnsyxM: "framer-v-obe5e6", XtSgKKwNB: "framer-v-i09f5t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Dark: "XtSgKKwNB", Light: "pTtJnsyxM"}

const getProps = ({height, id, text, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "pTtJnsyxM", VshpJedt4: text ?? props.VshpJedt4 ?? "SECTION NAME"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, VshpJedt4, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "pTtJnsyxM", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-obe5e6", className, classNames)} data-framer-name={"Light"} layoutDependency={layoutDependency} layoutId={"pTtJnsyxM"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, ...style}} {...addPropertyOverrides({XtSgKKwNB: {"data-framer-name": "Dark"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-10hvz4u"} data-styles-preset={"P8rPmEmnE"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-0f4ab4bd-730d-4a3e-bd70-049bfead3b9e, rgb(255, 101, 53)))"}}>SECTION NAME</motion.p></React.Fragment>} className={"framer-tq76gg"} data-framer-name={"Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"z5Zbe8hj6"} style={{"--extracted-r6o4lv": "var(--token-0f4ab4bd-730d-4a3e-bd70-049bfead3b9e, rgb(255, 101, 53))", "--framer-paragraph-spacing": "14px"}} text={VshpJedt4} variants={{XtSgKKwNB: {"--extracted-r6o4lv": "var(--token-c0deaf3a-1d87-41f8-bff6-c104dc30a7f8, rgb(255, 255, 255))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({XtSgKKwNB: {children: <React.Fragment><motion.p className={"framer-styles-preset-10hvz4u"} data-styles-preset={"P8rPmEmnE"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-c0deaf3a-1d87-41f8-bff6-c104dc30a7f8, rgb(255, 255, 255)))"}}>SECTION NAME</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CC3hP.framer-5dpru4, .framer-CC3hP .framer-5dpru4 { display: block; }", ".framer-CC3hP.framer-obe5e6 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 4px 8px 4px 8px; position: relative; width: min-content; }", ".framer-CC3hP .framer-tq76gg { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-CC3hP.framer-obe5e6 { gap: 0px; } .framer-CC3hP.framer-obe5e6 > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-CC3hP.framer-obe5e6 > :first-child { margin-left: 0px; } .framer-CC3hP.framer-obe5e6 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 111
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"XtSgKKwNB":{"layout":["auto","auto"]}}}
 * @framerVariables {"VshpJedt4":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervJ8vpmz20: React.ComponentType<Props> = withCSS(Component, css, "framer-CC3hP") as typeof Component;
export default FramervJ8vpmz20;

FramervJ8vpmz20.displayName = "Section Badge";

FramervJ8vpmz20.defaultProps = {height: 28, width: 111};

addPropertyControls(FramervJ8vpmz20, {variant: {options: ["pTtJnsyxM", "XtSgKKwNB"], optionTitles: ["Light", "Dark"], title: "Variant", type: ControlType.Enum}, VshpJedt4: {defaultValue: "SECTION NAME", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramervJ8vpmz20, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})